import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import Slider from 'react-slick'
import ArrowWhite from '../images/arrow-white.svg'
import HeroGallery from '../components/Project/HeroGallery'
import VimeoSection from '../components/Project/VimeoSection'
import HeroImage from '../components/Project/HeroImage'
import CarouselHero from '../components/Project/CarouselHero'
import VideoCarousel from '../components/Project/VideoCarousel'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ProjectWrapper = styled.main`
	padding: 0 20px;

	@media (min-width: 1024px) {
		padding: 0 50px;
	}
`

const HeroSection = styled.section``

const ProjectInfoWrapper = styled.section`
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	justify-content: center;
	align-items: center;

	h1,
	h2,
	h3,
	p {
		max-width: 55ch;
	}

	@media (min-width: 1024px) {
		padding-bottom: 50px;
		padding-top: 100px;
		align-items: baseline;
	}
`

const ProjectDescription = styled.aside`
	margin-bottom: 20px;
	text-align: center;

	p {
		margin-bottom: 1em;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@media (min-width: 1024px) {
		margin: 0 auto;
		width: 66%;
		box-sizing: border-box;
		h1,
		h2,
		h3,
		p {
			max-width: 75ch;
			padding-right: 20px;
		}
		span,
		p {
			font-size: 1.5rem;
			line-height: 1.8666666667rem;
		}
	}
`

const Credits = styled.ul`
	list-style: none;
	box-sizing: border-box;
	margin-bottom: 40px;
	text-align: center;
	margin: 0 auto;

	@media (min-width: 1024px) {
		width: 66%;
		margin: 0 auto;
		padding-top: 50px;
		h1,
		h2,
		h3,
		p {
			max-width: 75ch;
			padding-right: 20px;
		}
		span,
		p {
			font-size: 1.5rem;
			line-height: 1.8666666667rem;
		}
	}
`
const Credit = styled.li``

const CreditTitle = styled.span`
	font-family: 'GrotexRegular';
`

const CreditName = styled.span`
	font-family: 'GrotexLight';
`

const ProjectAssets = styled.section``

const Carousel = styled.section`
	.slick-prev,
	.slick-next {
		width: 20px;
		height: 20px;
		top: 0;
		bottom: 0;
		height: auto;
		z-index: 2;
		transform: translate(0, 0);

		&:before {
			display: block;
			content: '';
			height: 20px;
			width: 20px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	.slick-prev {
		left: 10px;
		&:before {
			background-image: url(${ArrowWhite});
			transform: rotate(180deg);
		}
	}
	.slick-next {
		right: 10px;
		&:before {
			background-image: url(${ArrowWhite});
			margin-left: auto;
			margin-right: 20px;
		}
	}

	@media (min-width: 1024px) {
		.slick-prev,
		.slick-next {
			width: 40px;
			height: 40px;
			top: 0;
			bottom: 0;
			height: auto;
			&:before {
				height: 40px;
				width: 40px;
			}
		}
		.slick-prev {
			left: 20px;
		}
		.slick-next {
			right: 20px;
		}
	}
`

const SlideshowImage = styled.img`
	max-width: 100%;
	width: 100%;
`

const MoreAssets = styled.ul`
	display: flex;
	flex-direction: column;
	list-style: none;
	margin-top: 20px;

	@media (min-width: 1024px) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
`

const MoreAssetListItem = styled.li`
	margin-bottom: 20px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	img {
		max-width: 100%;
		width: 100%;
	}

	@media (min-width: 1024px) {
		&.half-length {
			width: calc(50% - 10px);
		}

		&.quarter-length {
			width: calc(25% - 15px);
		}

		&.three-quarter-length {
			width: calc(75% - 5px);
		}

		&.full-length {
			width: 100%;
		}

		img {
			opacity: 0 !important;
		}
	}
`

const Project = ({ pageContext }) => {
	const [hero, setHero] = useState(false)

	const generateHero = section => {
		let newSection
		switch (section._type) {
			case 'galleryHero':
				newSection = <HeroGallery images={section.carousel} />
				setHero(newSection)
				break
			case 'videoHero':
				newSection = <VimeoSection alt={pageContext.title} video={section.heroVideo} poster={section.heroPoster} />
				setHero(newSection)
				break
			case 'imageHero':
				newSection = <HeroImage img={section.heroImage} />
				setHero(newSection)
				break
			case 'carouselHero':
				newSection = <CarouselHero images={section.carousel} />
				setHero(newSection)
				break
			case 'videoCarousel':
				newSection = <VideoCarousel videos={section.carousel} />
				setHero(newSection)
				break
			default:
				break
		}
	}

	useEffect(() => {
		generateHero(pageContext.hero[0])
		return () => {
			setHero(false)
		}
	}, [pageContext.hero])

	return (
		<Layout>
			<SEO title={pageContext.title} />
			<ProjectWrapper>
				<HeroSection>{hero}</HeroSection>

				<ProjectInfoWrapper>
					<ProjectDescription>
						<BlockContent blocks={pageContext._rawDescription} />
					</ProjectDescription>
					{pageContext.credits.length > 0 && (
						<Credits>
							{pageContext.credits.map((item, index) => {
								return (
									<Credit key={index}>
										<CreditTitle>{item.title}:</CreditTitle> <CreditName>{item.name}</CreditName>
									</Credit>
								)
							})}
						</Credits>
					)}
				</ProjectInfoWrapper>

				<ProjectAssets>
					<Carousel>
						{pageContext.slideshow.length > 0 && (
							<Slider>
								{pageContext.slideshow.map((item, index) => {
									return <SlideshowImage key={index} src={item.asset.url} alt={`Project Image for ${pageContext.title}`} />
								})}
							</Slider>
						)}
					</Carousel>
					{pageContext.assets.length > 0 && (
						<MoreAssets>
							{pageContext.assets.map((item, index) => {
								return (
									<MoreAssetListItem
										key={item._key}
										style={{
											backgroundImage: `url(${item.image.asset.url})`,
										}}
										className={item.width}>
										<img src={item.image.asset.url} alt={`Project Image for ${pageContext.title}`} />
									</MoreAssetListItem>
								)
							})}
						</MoreAssets>
					)}
				</ProjectAssets>
			</ProjectWrapper>
		</Layout>
	)
}
export default Project;
