import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowWhite from '../../images/arrow-white.svg';

const Carousel = styled.section`
	.slick-prev,
	.slick-next {
		width: 20px;
		height: 20px;
		top: 0;
		bottom: 0;
		height: auto;
		z-index: 2;
		transform: translate(0, 0);

		&:before {
			display: block;
			content: '';
			height: 20px;
			width: 20px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	.slick-prev {
		left: 10px;
		&:before {
			background-image: url(${ArrowWhite});
			transform: rotate(180deg);
		}
	}
	.slick-next {
		right: 10px;
		&:before {
			background-image: url(${ArrowWhite});
			margin-left: auto;
			margin-right: 20px;
		}
	}

	@media (min-width: 1024px) {
		margin-bottom: -5px;
		.slick-prev,
		.slick-next {
			width: 40px;
			height: 40px;
			top: 0;
			bottom: 0;
			height: auto;
			&:before {
				height: 40px;
				width: 40px;
			}
		}
		.slick-prev {
			left: 20px;
		}
		.slick-next {
			right: 20px;
		}
	}
`;

const SlideshowImage = styled.img`
	max-width: 100%;
	width: 100%;
`;

const CarouselComponent = ({ images }) => {
	return (
		<Carousel>
			<Slider>
				{images.map((item, index) => {
					return (
						<SlideshowImage
							key={index}
							src={item.asset.url}
							alt={'Slideshow'}
						/>
					);
				})}
			</Slider>
		</Carousel>
	);
};
export default CarouselComponent;
