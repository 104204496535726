import React, { useState } from 'react';
import Play from '../../images/PlayButton.svg';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import Img from 'gatsby-image';

const VimeoSection = styled.section`
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const PlaySection = styled.div`
	position: relative;
	cursor: pointer;
	transition: opacity 0.2s ease, z-index 0.2s ease;
	z-index: 5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&.playing {
		opacity: 0;
		z-index: 0;
	}
`;

const PlayButton = styled.img`
	align-self: center;
	position: absolute;
	top: 15px;
	max-width: 150px;
	@media (min-width: 1024px) {
		top: 37.5px;
		max-width: 500px;
	}
`;

const VimeoSectionComponent = ({ video, poster, alt }) => {
	const [playing, setPlaying] = useState(false);
	return (
		<VimeoSection>
			<PlaySection
				onClick={() => {
					setPlaying(true);
				}}
				className={playing ? 'playing' : 'hidden'}
			>
				{poster && (
					<React.Fragment>
						<Img fluid={poster.asset.fluid} alt={alt} />
						<PlayButton src={Play} />
					</React.Fragment>
				)}
			</PlaySection>

			<ReactPlayer
				url={video}
				controls={true}
				width={'100%'}
				height={'auto'}
				playing={playing}
			/>
		</VimeoSection>
	);
};

export default VimeoSectionComponent;
