import React from 'react';
import styled from 'styled-components';

const HeroGallery = styled.ul`
	display: flex;
	flex-direction: column;
	list-style: none;

	@media (min-width: 1024px) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: -20px;
	}
`;

const GalleryListItem = styled.li`
	margin-bottom: 20px;
	background-position: center;
	background-size: cover;
	background-repeat: no - repeat;

	img {
		max-width: 100%;
		width: 100%;
	}

	@media (min-width: 1024px) {
		img {
			opacity: 0;
		}

		&.half-length {
			width: calc(50% - 10px);
		}

		&.quarter-length {
			width: calc(25% - 15px);
		}

		&.three-quarter-length {
			width: calc(75% - 5px);
		}

		&.full-length {
			width: 100%;
		}
	}
`;

const HeroGalleryComponent = ({ images }) => {
	return (
		<HeroGallery>
			{images.map((item) => {
				return (
					<GalleryListItem
						key={item._key}
						style={{ backgroundImage: `url(${item.image.asset.url})` }}
						className={item.width}
					>
						<img src={item.image.asset.url} alt={'Gallery'} />
					</GalleryListItem>
				);
			})}
		</HeroGallery>
	);
};

export default HeroGalleryComponent;
