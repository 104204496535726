import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import Left from '../../images/left.png';
import Right from '../../images/right.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import VimeoSection from './VimeoSection';

const Carousel = styled.section`
	margin-bottom: 50px;
	padding-bottom: 50px;
	.slick-prev,
	.slick-next {
		width: 20px;
		height: 20px;
		top: 0;
		bottom: 0;
		height: auto;
		z-index: 3;
		transform: translate(0, 0);
		cursor: pointer;

		&:before {
			display: block;
			content: '';
			height: 20px;
			width: 20px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	.slick-prev {
		left: 10px;
		&:before {
			transform: rotate(180deg);
		}
	}
	.slick-next {
		right: 10px;
		&:before {
			margin-left: auto;
			margin-right: 20px;
		}
	}

	@media (min-width: 1024px) {
		.slick-dots {
			bottom: -30px;
			li button:before {
				font-size: 10px;
			}
		}
		margin-bottom: -25px;
		.slick-prev,
		.slick-next {
			width: 25%;
			height: 40px;
			top: 0;
			bottom: 55px;
			height: auto;
			&:before {
				background-image: none;
				height: 40px;
				width: 40px;
			}
		}
		.slick-prev {
			left: 0px;

			cursor: url(${Left}) 12 20, auto;
			&:before {
				margin-left: 10px;
			}
		}
		.slick-next {
			right: 0px;

			cursor: url(${Right}) 12 20, auto;
			&:before {
				margin-right: 10px;
			}
		}
	}
`;

const CarouselComponent = ({ videos }) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	return (
		<Carousel>
			<Slider {...settings}>
				{videos.map((item) => {
					return (
						<VimeoSection
							key={item._key}
							video={item.heroVideo}
							poster={item.poster}
						></VimeoSection>
					);
				})}
			</Slider>
		</Carousel>
	);
};
export default CarouselComponent;
